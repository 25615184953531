import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import styled from 'styled-components';
import {
  Box,
  Card,
} from '@mui/material';
import { pink } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';

import NewSelect from '../../../_molecules/Select'
import { actionCreators as middleUnitActions } from "../../../../redux/modules/middleUnit";
import { useQuery } from 'react-query'
import { getAPI } from '../../../../api'

import { useModal } from 'hook'
import SearchingGrammarContent from '../modal/SearchingGrammar'
import useGetGrammarsAll from '../modal/useGetGrammarsAll'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

const _additionalOptions = [
  { id: 1, sub_subsection_name: '중요' },
  { id: 2, sub_subsection_name: '대표유형' },
  { id: 3, sub_subsection_name: '서술형' },
  { id: 4, sub_subsection_name: '고난이도' },
  { id: 5, sub_subsection_name: '진짜 서술형' },
]

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Container = styled.div`
  overflow: visible;
  .MuiPaper-root{
    overflow: auto;
    padding-bottom: 10px;
  }
`
const SelectWrap = styled(Card)`
  background-color: #f6f6f6;
  width: 100%;
  height: 133px;
  padding: ${props => props.padding};
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); */
  grid-template-columns: ${props => props.gridcolumns};
  column-gap: 1rem;
  flex: ${props => props.flex};

  .MuiBox-root {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
  }
  #demo-multiple-chip {
    padding: 18px 10px 14px;
  }
`;
const CheckBoxWrap = styled(Card)`
  background-color: #f6f6f6;
  width: fit-content;
  height: 8.3rem;
  padding: 15px 25px 15px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: ${props => props.flex};

  .MuiCheckbox-root {
    padding: 6px 8px 6px;
  }
`;
const CheckItem = styled.div`
  width: 100%;
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const MiddleUnitToolbar = (props) => {
  const { setAllData, focusButton, questionId } = props
  const dispatch = useDispatch()
  const _nation = localStorage.getItem("_nation")
  const _authority = localStorage.getItem("_authority")
  const approval_nation = localStorage.getItem('approval_nation')

  const is_books = useSelector((state) => state.middleUnit.is_books) // current
  const subject = useSelector((state) => state.middleUnit.subject) // current
  const sub_unit_section_list = useSelector((state) => state.middleUnit.sub_unit_section) // current
  const sub_section = useSelector((state) => state.middleUnit.sub_section) // current
  const grammar_list = useSelector((state) => state.middleUnit.grammars) // current
  const additional_option = useSelector((state) => state.middleUnit.additional_option) // current

  const error_check_list = useSelector((state) => state.middleUnit.error_check_list)
  const content_id = useSelector((state) => state.middleUnit.content_id)
  const is_multiple = useSelector((state) => state.middleUnit.is_multiple)

  const additionalOptions = is_multiple === 1 ? _additionalOptions.filter(v => v.id !== 5) : _additionalOptions

  const { data: thai_sub_subject_data } = useQuery([`question/${questionId}/sub-subject`], () => getAPI(`question/${questionId}/sub-subject`), {
    enabled: !!(questionId.includes('thai_')),
    select: data => {
      return data?.data
    },
  })

  const [thaiSubSubject, setThaiSubSubject] = useState([])

  const handleThaiSubSubject = (list) => {
    setThaiSubSubject(list)
  }

  useEffect(() => {
    if (thaiSubSubject.length === 0) return
    const thai_id = thaiSubSubject.length === 0 ? 0 : thaiSubSubject[0].id
    setSubSection([])
    dispatch(middleUnitActions.getMiddleUnitOption(questionId, thai_id)) // 중단원 옵션 리스트
  }, [thaiSubSubject])

  const [related, setRelated] = useState('0')
  const [text, setText] = useState('0')
  const [answer, setAnswer] = useState('0')
  const [isPyeong, setIsPyeong] = useState('0')


  const handleErrors = (e) => {
    if (e.target.name === 'related_error') {
      setRelated(e.target.checked ? '1' : '0')
    } else if (e.target.name === 'text_error') {
      setText(e.target.checked ? '1' : '0')
    } else if (e.target.name === 'answer_error') {
      setAnswer(e.target.checked ? '1' : '0')
    } else if (e.target.name === 'isPyeong_error') setIsPyeong(e.target.checked ? '1' : '0')
  }

  // ------------------------------------------------------------ 중단원, 소단원

  const [subSection, setSubSection] = useState([]);
  const [grammars, setGrammars] = useState([]);
  const [additionalOption, setAdditionalOption] = useState([]);

  // ------------------------------------------------------------

  const handleSubsectionClick = (list) => {
    setSubSection(list)
  }

  const { data: grammarsAll } = useGetGrammarsAll()

  const [keyword, setKeyword] = useState('') // 문법 검색 value
  const [searchedKeyword, setSearchedKeyword] = useState('') // 문법 검색 키워드
  const [selected, setSelected] = useState(null) // 선택된 문법 태그

  const handleChangeTags = (grammar) => {
    setGrammars((prev) => {
      const included = !!prev.find(g => g.grammar_child_unit_id === grammar.grammar_child_unit_id)
      if (!included) prev.push(grammar)

      return [...prev]
    })
  }

  const handleDeleteTag = (grammarId) => {
    setGrammars((prev) => {
      prev = prev.filter(g => g.grammar_child_unit_id !== grammarId)
      return [...prev]
    })
  }

  const handleKeyword = (e) => {
    const { value } = e.target
    setKeyword(value)
  }

  const handleSearchedKeyword = (value) => setSearchedKeyword(value)

  const handleSearch = () => {
    handleSearchedKeyword(keyword)
    setSelected(null)
  }

  const handleSelect = (grammar) => {
    setSelected((prev) => {
      if (prev?.grammar_child_unit_id === grammar.grammar_child_unit_id) return null
      return grammar
    })
  }

  const handleInitSearching = () => {
    setKeyword('')
    setSearchedKeyword('')
    setSelected(null)
  }

  const handleCompleteSearching = () => {
    handleChangeTags(selected)
    handleCloseSearching()
  }

  const handleClickGrammar = () => {
    handleOpenSearching()
  }

  const SearchingGrammar = useModal(
    <SearchingGrammarContent
      keyword={keyword}
      searchedKeyword={searchedKeyword}
      selected={selected}
      handleKeyword={handleKeyword}
      handleSelect={handleSelect}
      onComplete={handleCompleteSearching}
      onSearch={handleSearch}
      onClose={handleCloseSearching}
    />,
    { onClickBackdrop: handleInitSearching }
  )

  function handleCloseSearching() {
    SearchingGrammar.close()
    handleInitSearching()
  }
  function handleOpenSearching() { SearchingGrammar.showModal() }

  const checkGrammar = (subSection) => {
    const hasGrammar = subSection.some(v => v.name.includes('grammar') || v.name.includes('문법'))

    return hasGrammar ? (!!grammars.length ? true : false) : true
  }

  useEffect(() => {
    return () => {
      setSubSection([])
      setAdditionalOption([])
    }
  }, [])

  useEffect(() => {
    if (sub_section) {
      if (sub_section.length > 0) setSubSection(sub_section)
    }
  }, [sub_section])

  useEffect(() => {
    if (!subSection.some(v => v.name.includes('grammar') || v.name.includes('문법'))) {
      setGrammars([])
    }
  }, [subSection])

  useEffect(() => {
    if (grammar_list && grammarsAll?.data) {
      const list = grammar_list.map(v => {
        const grammar = grammarsAll.data.find(g => g.grammar_child_unit_id === v)
        return grammar
      })
      setGrammars(list)
    }
  }, [grammar_list, grammarsAll])

  useEffect(() => {
    if (additional_option.length > 0) {
      const newArr = additionalOptions.filter(el => {
        const arr = additional_option.filter(v => v === el.id)
        return arr.length > 0
      })
      setAdditionalOption(newArr)
    } else {
      setAdditionalOption([])
    }
  }, [additional_option])

  useEffect(() => {
    if (error_check_list.length === 0) return
    setRelated(error_check_list[0])
    setText(error_check_list[1])
    setAnswer(error_check_list[2])
    setIsPyeong(error_check_list[3])
  }, [error_check_list])

  useEffect(() => {
    if (is_books && subSection.length > 0) {
      focusButton(checkGrammar(subSection))
      let data = {
        question_id: questionId,
        content_id: String(content_id),
        sub_sections: subSection.map(obj => obj.id),
        grammar_child_unit_ids: !!grammars.length ? grammars.map(obj => obj.grammar_child_unit_id) : null,
        errors: [Number(related), Number(text), Number(answer), Number(isPyeong)],
        tags: additionalOption.length > 0 ? additionalOption.map(el => el.id) : null,
      }

      setAllData(data)
    } else if (!is_books && related && text && answer && isPyeong && questionId.split('_')[0] !== 'TH') {
      focusButton(checkGrammar(subSection))
      const data = {
        question_id: questionId,
        content_id: String(content_id),
        sub_sections: subSection.map(obj => obj.id),
        grammar_child_unit_ids: !!grammars.length ? grammars.map(obj => obj.grammar_child_unit_id) : null,
        errors: [Number(related), Number(text), Number(answer), Number(isPyeong)]
      }

      setAllData(data)
    } else if (subSection.length > 0) {
      focusButton(true)
      const data = {
        question_id: questionId,
        subsection_id: subSection.map(obj => obj.id),
      }
      setAllData(data)

    } else {
      focusButton(false)
    }
  }, [
    related,
    text,
    answer,
    isPyeong,
    subSection,
    grammars,
    additionalOption
  ])

  const hasGrammar = subSection.some(v => v.name.includes('grammar') || v.name.includes('문법'))

  const getGridColumns = () => {
    let count = 1
    if (is_books) {
      count += 1
      if (questionId.includes('thai_')) count += 1
    }
    if (subject === '영어') count += 1

    return `repeat(${count}, 1fr)`
  }
  console.log(grammars)

  return sub_unit_section_list.length > 0 && (
    <Container>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', gap: '16px', overflow: 'visible' }}>
        <SelectWrap flex={5} padding={_nation === '1' ? '10px 10px 0' : '5px 15px'} style={{ overflow: 'visible' }} gridcolumns={getGridColumns()}>
          {questionId.includes('thai_') &&
            <NewSelect label={'태국 세부과목'} selected_list={thaiSubSubject} option_list={thai_sub_subject_data ? thai_sub_subject_data : []} onSelect={handleThaiSubSubject} />
          }
          <NewSelect multiple={!is_books} label={'중단원'} selected_list={subSection} option_list={sub_unit_section_list} onSelect={handleSubsectionClick} />
          {hasGrammar &&
            <NewSelect multiple label={'영문법'} selected_list={grammars.map(g => { return { id: g.grammar_child_unit_id, name: g.grammar_child_unit_name } })} option_list={[]} onClick={handleClickGrammar} handleDeleteTag={handleDeleteTag} />
          }
          {is_books &&
            <>
              <NewSelect multiple={true} label={'추가 선택'} selected_list={additionalOption} option_list={additionalOptions} onSelect={setAdditionalOption} />
            </>
          }

        </SelectWrap>

        {questionId.split('_')[0] !== 'TH' && approval_nation !== '3' &&
          <CheckBoxWrap >
            <CheckItem>
              <Checkbox
                {...label}
                sx={{
                  '&.Mui-checked': {
                    color: pink[600],
                  },
                }}
                name='related_error'
                onChange={handleErrors}
                checked={related === '1'}
              />
              <div className='title'>연관이미지 유무&nbsp;</div>
            </CheckItem>
            <CheckItem>
              <Checkbox
                {...label}
                sx={{
                  '&.Mui-checked': {
                    color: pink[600],
                  },
                }}
                name='text_error'
                onChange={handleErrors}
                checked={text === '1'}
              />
              <div className='title'>텍스트 작성 오류</div>
            </CheckItem>
            <CheckItem>
              <Checkbox
                {...label}
                sx={{
                  '&.Mui-checked': {
                    color: pink[600],
                  },
                }}
                name='answer_error'
                onChange={handleErrors}
                checked={answer === '1'}
              />
              <div className='title'>정확한 정답 여부</div>
            </CheckItem>
            {_authority === '0' &&
              (<CheckItem>
                <Checkbox
                  {...label}
                  sx={{
                    '&.Mui-checked': {
                      color: pink[600],
                    },
                  }}
                  name='isPyeong_error'
                  onChange={handleErrors}
                  checked={isPyeong === '1'}
                />
                <div className='title'>큐비문제집</div>
              </CheckItem>)
            }
          </CheckBoxWrap>
        }
      </Box>
      {SearchingGrammar.Dialog}
    </Container>
  )
}

export default React.memo(MiddleUnitToolbar);
